@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

.androidWrapper {
  // background-color: #6b4fff;
  // background-color: rgb(105, 58, 58);
  width: 80%;
  height: "auto";
  padding: 1% 5%;
  margin-top: 0px;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 15px;
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 2%;
  box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px;
  .androidBox {
    background-color: #fff;
    width: "auto";
    height: "auto";
    padding: 4% 0%;
    border-radius: 45px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    cursor: pointer;
    .androidIconContainer {
      // background-color: #6b4fff;
      width: "auto";
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0px;

      .androidIcon {
        width: 20%;
        height: 90%;
        border-radius: 30px;
        // filter: sepia(1) saturate(10000%) hue-rotate(180deg);
      }
    }
  }
  .QrCode {
    width: 25%;
    height: 90%;
    border-radius: 30px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  }
}

@media screen and (max-width: 501px) {
  .androidWrapper {
    // background-color: #6b4fff;
    // background-color: rgb(105, 58, 58);
    width: 80%;
    height: "auto";
    padding: 1% 5%;
    margin-top: 0px;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 2%;
    box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px;
    .androidBox {
      background-color: #6b4fff00;
      margin-top: 30px;
      width: "auto";
      height: "auto";
      padding: 4% 0%;
      border-radius: 60px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
      cursor: pointer;

      .androidIconContainer {
        // background-color: #6b4;
        width: "auto";
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;

        .androidIcon {
          width: 15%;
          height: 90%;
          border-radius: 30px;
          // filter: sepia(1) saturate(10000%) hue-rotate(180deg);
        }
      }
    }
    .QrCode {
      width: 90%;
      height: 90%;
      border-radius: 30px;
      margin-top: 30px;
      padding: 15px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    }
  }
}
