@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

.Wrapper2 {
  background-color: #6b4fff;
  // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
  //background-color: rgb(255, 255, 255);
  width: 90%;
  margin-top: 30px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 2% 0;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.21) 0px 5px 15px;

  .QrText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    .text1 {
      font-family: "Quicksand", sans-serif;
      color: #fff;
      font-size: 5em;
      text-align: center;
      font-weight: 900;
    }
    .text2 {
      font-family: "Quicksand", sans-serif;
      color: #fff;
      font-size: 3em;
      text-align: center;
      font-weight: 300;
    }
  }

  .QRImage {
    width: 20%;
    height: 60%;
    border-radius: 20px;
    margin-top: 0px;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.0) 0px 5px 15px;

  }
}

// ///////////////////
@media screen and (max-width: 501px) {
  .Wrapper2 {
    background-color: #6b4fff00;
    // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
    //background-color: rgb(255, 255, 255);
    width: 100%;
    margin-top: 30px;
    margin-left: 0%;
    margin-right: 0%;
    padding: 2% 0;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px;

    .QrText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      .text1 {
        font-family: "Quicksand", sans-serif;
        color: #6b4fff;
        font-size: 2em;
        text-align: center;
        font-weight: 900;
      }
      .text2 {
        font-family: "Quicksand", sans-serif;
        color: #6b4fff;
        font-size: 1.5em;
        text-align: center;
        font-weight: 300;
      }
    }

    .QRImage {
      width: 70%;
      height: 60%;
      border-radius: 20px;
      margin-top: 30px;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    }
  }
}
