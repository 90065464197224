@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

.fourthComponentsContainer {
  // background-color:  #6b4fff;
  // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
  //background-color: rgb(255, 255, 255);
  width: 90%;
  margin-top: 30px;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .fourthcomptext {
    color: #6b4fff;
    font-family: "Quicksand", sans-serif;
    font-size: 7em;
    text-align: center;
    font-weight: 300;
    white-space: pre;
    // background-image: linear-gradient(to right, #ffce2d, #ff2600); /* Gradient colors */
    // background-image: linear-gradient(to right, #8c11f1, #ffc400); /* Gradient colors */
    // -webkit-background-clip: text; /* Apply background to text only */
    // -webkit-text-fill-color: transparent; /* Hide text color */
  }
  .fourthImage {
    width: 35%;
    height: 70%;
    margin-top: 30px;
    border-radius: 30px;
    border-radius: 30px;
    box-shadow: none;
  }
}

@media screen and (max-width: 501px) {
  .fourthComponentsContainer {
    // background-color:  #6b4fff;
    // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
    //background-color: rgb(255, 255, 255);
    width: 90%;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .fourthcomptext {
      color: #6b4fff;
      font-family: "Quicksand", sans-serif;
      font-size: 2em;
      text-align: center;
      font-weight: 300;
      white-space: pre;
      // background-image: linear-gradient(to right, #ffce2d, #ff2600); /* Gradient colors */
      // background-image: linear-gradient(to right, #8c11f1, #ffc400); /* Gradient colors */
      // -webkit-background-clip: text; /* Apply background to text only */
      // -webkit-text-fill-color: transparent; /* Hide text color */
    }
    .fourthImage {
      width: 75%;
      height: 70%;
      margin-top: 30px;
      border-radius: 30px;
      border-radius: 30px;
      // box-shadow:  rgba(17, 17, 26, 0.1) 0px 24px 80px;
    }
  }
}
