@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&family=Moon+Dance&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}
body {
  align-items: center;
  justify-content: center;
  align-items: center;
}

:root {
  --bg: #5c289f;
  --bgpri: #5c289f;
  --bg-accent: #484a4d;
  --text-color: #dedede;
  --nav-size: 60px;
  --border: 1px solid #414141;
  --border-radius: 9px;
  --speed: 500ms;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  width: 5px;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(153, 153, 153);
  width: 6px;
  border-radius: 10px;
}

.Container {
  // background-color: #fff;
  /* fallback for old browsers */
  // background-color: #6b4fff;
  // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 501px) {
  }

  .Wrapper {
    // background-color:  #6b4fff;
    // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
    //background-color: rgb(255, 255, 255);
    margin-top: 30px;
    padding: 30px 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    @media screen and (max-width: 501px) {
    }

    .logo {
      // color:#6b4fff;
      font-family: "Quicksand", sans-serif;
      font-size: 7em;
      text-align: center;
      font-weight: 300;
      white-space: pre;
      background-image: linear-gradient(
        to right,
        #6b4fff,
        #f51987
      ); /* Gradient colors */
      // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
      -webkit-background-clip: text; /* Apply background to text only */
      -webkit-text-fill-color: transparent; /* Hide text color */

      @media screen and (max-width: 501px) {
        display: none;
      }
    }
    .title {
      font-family: "Quicksand", sans-serif;
      color: #999999;
      font-size: 4em;
      text-align: center;
      font-weight: 100;
      margin-top: 15px;

      @media screen and (max-width: 501px) {
        display: none;
      }
    }
  }
  .button_Container {
    z-index: 1;
    transition: 0.3s cubic-bezier(0.25, 1, 0.5, 1);
    margin-top: 45px;
    margin-bottom: 150px;
    // background-color: #6b4fff;
    // background-color: #006fff;
    width: 70%;
    height: 200px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    @media screen and (max-width: 501px) {
      display: none;
    }

    .button {
      // width: 100%;
      min-height: 50px;
      background-color: #6b4fff10;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
      border-radius: 30px;
      padding: 20px 50px;
      // box-shadow: rgba(0, 0, 0, 0.21) 0px 5px 15px;
      cursor: pointer;

      @media screen and (max-width: 501px) {
        display: none;
      }

      .text {
        // background-color:#6b4fff;
        border-radius: 10px;
        font-family: "Quicksand", sans-serif;
        text-transform: capitalize;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6b4fff;
        font-weight: 900;
      }
    }
  }
}
