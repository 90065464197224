@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}
.container {
  display: flex;
  justify-content: center; // Horizontally center the line
  align-items: center;

  .HowToPlayContainer {
    background-color: #05001f;
    // background-image: linear-gradient(180deg, #ff00f2 0%, #efe1ff 46%, #7300ff 100%);
    // background-color: #ffffff;
    width: 90%;
    margin-top: 60px;
    gap: 0px;
    border-radius: 30px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;

    .howtoplayHeader {
      color: #fff;
      font-family: "Quicksand", sans-serif;
      font-size: 2em;
      text-align: center;
      font-weight: 400;
      margin-top: 60px;
      margin-bottom: 60px;
      background-color: #6b4fff;
      border-radius: 300px;
      padding-inline: 60px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;

      //   white-space: pre;
      //   background-image: linear-gradient(
      //     to right,
      //     #6b4fff,
      //     #301d8d
      //   ); /* Gradient colors */
      // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
      //   -webkit-background-clip: text; /* Apply background to text only */
      //   -webkit-text-fill-color: transparent; /* Hide text color */
    }
    .HowToPlayImage {
      width: 25%;
      height: 20%;
      border-radius: 30px;
      align-self:'center';
    }

    .HowToPlaytextContainerThird {
      // background-color: #ff2600;
      display: "flex";
      flex-direction: "column";
      align-items: "center";
      justify-content: "center";
      margin-top: 0px;

      .HowToPlaythirdcomptext {
        // color:#6b4fff;
        font-family: "Quicksand", sans-serif;
        font-size: 5em;
        text-align: center;
        font-weight: 600;
        //   white-space: pre;
        background-image: linear-gradient(
          to right,
          #ffce2d,
          #ff2600
        ); /* Gradient colors */
        // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
        -webkit-background-clip: text; /* Apply background to text only */
        -webkit-text-fill-color: transparent; /* Hide text color */
      }
      .HowToPlaythirdcomptextNote {
        // color:#6b4fff;
        font-family: "Quicksand", sans-serif;
        font-size: 1em;
        text-align: center;
        font-weight: 100;
        color: "#dedede";
      }
    }
  }
}

@media screen and (max-width: 501px) {
  .container {
    display: flex;
    justify-content: center; // Horizontally center the line
    align-items: center;

    .HowToPlayContainer {
      background-color: #05001f;
      // background-image: linear-gradient(180deg, #ff00f2 0%, #efe1ff 46%, #7300ff 100%);
      // background-color: #ffffff;
      width: 100%;
      margin-top: 60px;
      gap: 0px;
      border-radius: 0px;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;

      .howtoplayHeader {
        color: #fff;
        font-family: "Quicksand", sans-serif;
        font-size: 1.5em;
        text-align: center;
        font-weight: 400;
        margin-top: 60px;
        margin-bottom: 60px;
        background-color: #6b4fff;
        border-radius: 300px;
        padding-inline: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;

        //   white-space: pre;
        //   background-image: linear-gradient(
        //     to right,
        //     #6b4fff,
        //     #301d8d
        //   ); /* Gradient colors */
        // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
        //   -webkit-background-clip: text; /* Apply background to text only */
        //   -webkit-text-fill-color: transparent; /* Hide text color */
      }
      .HowToPlayImage {
        width: 100%;
        height: 70%;
        border-radius: 0px;
        align-self: 'center';
      }

      .HowToPlaytextContainerThird {
        // background-color: #ff2600;
        display: "flex";
        flex-direction: "row";
        align-items: 'center';
        align-items: "center";
        justify-content: "center";
        margin-top: 0px;

        .HowToPlaythirdcomptext {
          // color:#6b4fff;
          font-family: "Quicksand", sans-serif;
          font-size: 5em;
          text-align: center;
          font-weight: 600;
          //   white-space: pre;
          background-image: linear-gradient(
            to right,
            #ffce2d,
            #ff2600
          ); /* Gradient colors */
          // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
          -webkit-background-clip: text; /* Apply background to text only */
          -webkit-text-fill-color: transparent; /* Hide text color */
        }
        .HowToPlaythirdcomptextNote {
          // color:#6b4fff;
          font-family: "Quicksand", sans-serif;
          font-size: 1em;
          text-align: center;
          font-weight: 100;
          color: "#dedede";
        }
      }
    }
  }
}
