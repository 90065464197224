/* Modal.scss */
.modal {
  position: fixed;
  top: 45px;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #6b4fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  animation: fadeIn 0.5s ease-in-out;

  ::-webkit-scrollbar {
    display: none;
  }

  &-main {
    width: 90%;
    max-height: 90%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0);
    padding: 0px;
    border-radius: 0px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    animation: scaleUp 0.5s ease-in-out;

        /* Keyframe animations */
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes scaleUp {
        from {
          transform: scale(0.5);
          opacity: 0;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }
    
    .itemsLink {
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap:10px;
      margin-top: 50px;

      .optionsItem{
        color: #fff;
        font-weight: lighter;
        font-size: 1.5em;
        font-family: "Quicksand", sans-serif;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  .display-block {
    display: block;
  }
}

.display-none {
  display: none;
}
