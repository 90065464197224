@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

.secondComponentsContainer {
  // background-color:  #6b4fff;
  // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
  //background-color: rgb(255, 255, 255);
  width: 90%;
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 30px;
  gap: 0px;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .textContainerSecond {
    display: "flex";
    flex-direction: "column";
    align-items: "flex-start";
    justify-content: "flex-start";

    .seccomptext {
      // color:#6b4fff; 
      font-family: "Quicksand", sans-serif;
      font-size: 7em;
      text-align: center;
      font-weight: 300;
      //   white-space: pre;
      background-image: linear-gradient(
        to right,
        #6b4fff,
        #f51987
      ); /* Gradient colors */
      // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
      -webkit-background-clip: text; /* Apply background to text only */
      -webkit-text-fill-color: transparent; /* Hide text color */
    }
  }
  .secondImage {
    width: 30%;
    height: 30%;
    border-radius: 30px;
  }
}

@media screen and (max-width: 501px) {
  .secondComponentsContainer {
    // background-color:  #6b4fff;
    // background-image: linear-gradient(180deg, #1b003b 0%, #4a00a5 46%, #1b003b 100%);
    //background-color: rgb(255, 255, 255);
    width: 90%;
    margin-top: 30px;
    padding-left: 5%;
  padding-right: 5%;
    border-radius: 30px;
    gap: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .textContainerSecond { 
      // background-color:  #6b4fff;
      display: "flex";
      flex-direction: "column";
      align-items: "center";
      justify-content: "center";

      .seccomptext {
        // color:#6b4fff;
        font-family: "Quicksand", sans-serif;
        font-size: 3em;
        text-align: center;
        font-weight: 300;
        margin-bottom: 0px;
        // white-space: pre;
        background-image: linear-gradient(
          to right,
          #6b4fff,
          #f51987
        ); /* Gradient colors */
        // background-image: linear-gradient(to right, #f12711, #f5af19); /* Gradient colors */
        -webkit-background-clip: text; /* Apply background to text only */
        -webkit-text-fill-color: transparent; /* Hide text color */
      }
    }
    .secondImage {
      width: 240px;
      height: 240px;
    }
  }
}
