.buttonAndroid {
  // background-color: #d4d4d4;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .androidTextlogo {
    background-color: transparent;
    color: #6b4fff;
    font-size: 1.5em;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    border: none;
  }
}
.buttonComingsoon {
  // background-color: #d4d4d4;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .androidTextlogoComingsoon {
    background-color: transparent;
    color: #6b4fff;
    font-size: 1.2em;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    border: none;
  }
}
