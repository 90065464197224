@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

.header {
  width: 98%;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0% 2% 0% 0%; /* Add padding as needed */
  transition: box-shadow 0.3s ease; /* Add a smooth transition for the shadow */

  .appIcon {
    // background-color: #6b4fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 2%;

    .appName {
      color: #6b4fff;
      font-weight: lighter;
      font-size: 24px;
      font-family: "Quicksand", sans-serif;
      font-weight: 900;

    }
  }
  .optionsItem{
    color: #ffffff;
    font-weight: lighter;
    font-size: 30px;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;

  }

  .headerOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 60px;
    gap: 25px;
    margin-left: 90px;
    background-color: #ffffff;

    .optionText {
      color: #6b4fff;
      font-weight: lighter;
      font-size: 1.2em;
      font-family: "Quicksand", sans-serif;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
.shadow {
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.255);
}

@media screen and (max-width: 501px) {
  .header {
    width: 98%;
    height: 45px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0%;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0% 2% 0% 0%; /* Add padding as needed */
    transition: box-shadow 0.3s ease; /* Add a smooth transition for the shadow */

    .appIcon {
      // background-color: #6b4fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 2%;
 
      .appName { 
        display: none;
        color: #6b4fff;
        font-weight: lighter;
        font-size: 18px;
        font-family: "Quicksand", sans-serif;
        font-weight: 100;
      }
    }
    .headerOptions {
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 25px;
      margin-left: 90px;
      height: 60px;
      // background-color: #484a4d;

      .optionText {
        color: #6b4fff;
        font-weight: lighter;
        font-size: 1.2em;
        font-family: "Quicksand", sans-serif;
        font-weight: 600;
      }
    }
  }
  .shadow {
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.1);
  }
}
