.headLine {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .textHeadline {
    color: #6b4fff;
    font-weight: lighter;
    font-size: 2em;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    margin-top: 0;
  }
  .content {
    background-color: #6b4fff00;
    width: 90%;
    padding: 20px;
    border-radius: 20px;
    // border-color: #6b4fff;
    // border-width: 1px;
    // border-style: solid; /* Required to make the border visible */
    margin-bottom: 30px;
    margin-top: 0;
  }
}
