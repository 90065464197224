.footerContainer {
  width: 100%;
  height: 600px;
  background-color: #060024;
  // background-color: #333333;
  // background-color: #333333;
  margin-top: 30px;
  margin-bottom: 0px;
  flex-direction: column;
  position: relative;
  display: flex;

  .subContainer {
    // background-color: #fff333;
    width: 85%;
    height: 150px;
    margin-top: 30px;
    padding-left: 60px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .footerAppname {
      color: #ffffff;
      font-weight: lighter;
      font-size: 2em;
      font-family: "Quicksand", sans-serif;
      font-weight: 700;
      margin-top: 10px;
    }
    .footerAppname-com {
      // background-color: rgb(255, 255, 255);
      border-radius: 30px;
      color: #fff;
      padding: 5px;
      font-weight: lighter;
      font-size: 1.5em;
      font-family: "Quicksand", sans-serif;
      font-weight: 100;
      margin-top: 3px;
    }
  }
  .linkIcons {
    width: 85%;
    height: 50px;
    // background-color: #ff4040;
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 10px;
    padding-left: 60px;
  }
  .termsAndContainer {
    width: 250px;
    background-color: #ffffff00;
    margin-left: 50px;
    margin-top: 10px;
  }
  .linkContainer {
    width: 200px;
    padding: 10px;
    margin: 10px;
    border-radius: 50px;
    background-color: #6b4fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .linkContainerText {
    color: #ffffff;
    margin: 0 2px; /* Adds space between the text elements */
    font-weight: lighter;
    font-size: 1.1em;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
  }
  .textContainer {
    width: 80%;
    height: 500px;
    // background-color: #060024;
    // background-color: #a0a0a0;
    // background-color: #333333;
    margin-top: 10px;
    margin-left: 60px;
    flex-direction: column;
    position: relative;
    display: flex;
  }

  .footerBase {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #6b4fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footerBaseText {
    color: #fff;
    margin: 0 2px; /* Adds space between the text elements */
    // font-style: italic;
    text-align: center;
    font-weight: 600;
  }
}
