@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&family=Moon+Dance&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


@mixin mobile { 
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

* {
  scroll-snap-type: none !important;
}
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Optional: to prevent horizontal scrolling */
}
:root {
  --bg: #5c289f;
  --bgpri: #5c289f;
  --bg-accent: #484a4d;
  --text-color: #dedede;
  --nav-size: 60px;
  --border: 1px solid #414141;
  --border-radius: 9px;
  --speed: 500ms;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  width: 5px;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(153, 153, 153);
  width: 6px;
  border-radius: 10px;
}

/* styles.css */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Optional: To hide the 'number' input's default increment and decrement buttons in Safari */
input[type="number"] {
  -webkit-appearance: textfield;
}
