@mixin mobile {
  @media screen and (max-width: 501px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1860px) {
    @content;
  }
}

.Wrapper1 {
  // background-color: #6fff;
  // background-color: rgb(255, 255, 255);
  width: 80%;
  height: "auto";
  padding: 3% 10%;
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0%;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;

  .bannerImage {
    width: 55%;
    height: 90%;
    box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px;
  }

  .textContainer {
    // background-color: #d4d4d4;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo1 {
      color: #6b4fff;
      font-size: 7em;
      font-family: "Quicksand", sans-serif;
      font-weight: 700;
    }
    .title1 {
      font-family: "Quicksand", sans-serif;
      color: #6b4fff;
      font-size: 3em;
      text-align: center;
      font-weight: 300;
    }
    .title2 {
      font-family: "Quicksand", sans-serif;
      color: #6b4fff;
      font-size: 1.8em;
      text-align: center;
      font-weight: 300;
    }
    .title3 {
      font-family: "Quicksand", sans-serif;
      color: #6b4fff;
      font-size: 1.8em;
      text-align: center;
      font-weight: 300;
    }
    .downloadButton {
      // background-color: #6b4fff;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 10vh; // Ensure the container has some height to see the centering effect
      gap: 20px;
      margin-top: 30px;

      .downloadImageGoogle {
        width: 45%;
        height: 23%;
        border-radius: 15x;
      }
      .downloadImageApple {
        width: 45%;
        height: 23%;
        border-radius: 15px;
      }
    }

    .inputContainer {
      // background-color: #05f;
      width: 65%;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 25px;

      .roundedInput {
        background-color: #f6f6f6;
        // background-color: #4c34c4;
        height: 10px;
        width: 90%;
        border-radius: 60px;
        padding: 30px;
        padding-left: 00px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        // box-shadow: rgba(0, 0, 0, 0.21) 0px 5px 15px;

        .extention {
          // background-color: #ebebeb;
          // background-color: #d6c8ff;
          // background-color: #312066;
          height: 10px;
          width: 10%;
          padding: 30px;
          border-radius: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .extenText {
            color: #6b4fff;
            font-family: "Quicksand", sans-serif;
            font-size: 1.5em;
            font-weight: 600;
          }
        }
        input {
          background-color: transparent;
          height: 50px;
          width: 90%;
          padding: 10px;
          font-family: "Quicksand", sans-serif;
          font-size: 1.2em;
          text-align: left;
          font-weight: 600;
          outline: none;
          border: none;
          color: #6b4fff;
        }
        input.roundedInput:focus {
          outline: none;
          border: none;
          color: #6b4fff;
        }
        input::placeholder {
          font-family: "Quicksand", sans-serif;
          font-size: 1em;
          font-weight: 600;
        }
      }
      .getotpBtn {
        background-color: #f6f6f6;
        width: 25%;
        height: 20px;
        padding: 20px;
        border-radius: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        display: none;

        .getotpText {
          font-family: "Quicksand", sans-serif;
          color: #fff;
          font-size: 1em;
          text-align: center;
          font-weight: 700;
        }
      }
      .getotpBtngreen {
        background-color: #6b4fff;
        // background-color: #f00;
        // background-color: #6aff00;
        width: 25%;
        height: 20px;
        padding: 20px;
        border-radius: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
    .downloadBtn {
      background-color: #6b4fff;
      height: 330px;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 501px) { 
  .Wrapper1 {
    // background-color: #6fff;
    // background-color: rgb(255, 255, 255);
    width: 80%;
    height: "auto";
    padding: 3% 10%;
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0%;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;

    .bannerImage {
      width: 85%;
      height: auto;
      border-radius: 30px;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px;
    }

    .textContainer {
      // background-color: #d4d4d4;
      padding-left: 3%;
      padding-right: 3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo1 {
        color: #6b4fff;
        font-size: 3em;
        font-family: "Quicksand", sans-serif;
        font-weight: 700;
      }
      .title1 {
        font-family: "Quicksand", sans-serif;
        color: #6b4fff;
        font-size: 1.5em;
        text-align: center;
        font-weight: 300;
      }
      .title2 {
        font-family: "Quicksand", sans-serif;
        color: #6b4fff;
        font-size: 1.2em;
        text-align: center;
        font-weight: 300;
      }
      .title3 {
        font-family: "Quicksand", sans-serif;
        color: #6b4fff;
        font-size: 1.2em;
        text-align: center;
        font-weight: 300;
      }

      .downloadButton {
        // background-color: #6b4fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // height: 10vh; // Ensure the container has some height to see the centering effect
        gap: 20px;
        margin-top: 30px;

        .downloadImageGoogle {
          width: 60%;
          height: 23%;
          border-radius: 15px;
        }
        .downloadImageApple {
          width: 60%;
          height: 23%;
          border-radius: 15px;
        }
      }

      .inputContainer {
        // background-color: #05f;
        width: 100%;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 15px;

        .roundedInput {
          background-color: #f6f6f6;
          // background-color: #4c34c4;
          height: 10px;
          width: 90%;
          border-radius: 60px;
          padding: 20px;
          padding-left: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          // box-shadow: rgba(0, 0, 0, 0.21) 0px 5px 15px;

          .extention {
            // background-color: #cdcdcd;
            // background-color: #312066;
            height: 10px;
            width: 10%;
            padding: 10px;
            border-radius: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .extenText {
              color: #6b4fff;
              font-family: "Quicksand", sans-serif;
              font-size: 1em;
              font-weight: 600;
            }
          }
          input {
            // background-color: #6b4fff;
            height: 50px;
            width: 90%;
            padding: 3px;
            font-family: "Quicksand", sans-serif;
            font-size: 1em;
            text-align: left;
            font-weight: 500;
            outline: none;
            border: none;
            color: #6b4fff;
          }
          input.roundedInput:focus {
            outline: none;
            border: none;
            color: #6b4fff;
          }
          input::placeholder {
            // color: #6b4fff; /* Change the color to your desired color */
            font-size: 1.2em;
          }
        }
        .getotpBtn {
          background-color: #f6f6f6;
          width: 25%;
          height: 20px;
          padding: 20px;
          border-radius: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          display: none;

          .getotpText {
            font-family: "Quicksand", sans-serif;
            color: #fff;
            font-size: 1em;
            text-align: center;
            font-weight: 700;
          }
        }
        .getotpBtngreen {
          background-color: #6b4fff;
          // background-color: #f00;
          // background-color: #6aff00;
          width: 25%;
          height: 20px;
          padding: 20px;
          border-radius: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
      .downloadBtn {
        background-color: #6b4fff;
        height: 330px;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
